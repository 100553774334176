body {
  margin: 0;
  font-family: 'AcuminPro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFF8F1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'AcuminPro';
  src: local("AcuminPro-Medium"),
  url("./fonts/AcuminPro-Medium.otf") format("truetype");
}

@font-face {
  font-family: 'Futura';
  src: local("FuturaLTPro-Bold"),
  url("./fonts/FuturaLTPro-Bold.otf") format("truetype");
}


.auth0-lock-widget{
  box-shadow: none !important;
  width: 500px !important;
  background: #F6ECE7 !important;
  border-radius: 29px !important;
  padding: 25px !important;
  overflow: hidden;
}

.auth0-lock-submit {
  width: 113px !important;
  height: 40px !important;
  border-radius: 25px !important;
  padding: 0px !important;
  padding-top: 1px !important;
  background-color: #000 !important;
  margin-top: 15px !important;
}

.auth0-label-submit{
  line-height: 0px !important;
  height: auto !important;
  margin-top: 12px !important;

}

.auth0-lock-container, .auth0-lock-cred-pane-internal-wrapper, 
.auth0-lock-header, .auth0-lock-content-wrapper, .auth0-lock-content-body-wrapper {
  background: #F6ECE7 !important;
}

.auth0-lock-header-bg{ display: none !important; }

.auth0-lock-widget-container {
  font-size: 17px !important;
  font-family: "AcuminPro" !important; 
}

.auth0-lock-overlay, .auth0-lock-header-logo, .icon-text, .auth0-lock-icon { display: none !important; }

.auth0-lock-input-wrap {
  padding-left: 0 !important;
  border-radius: 11px !important;
  padding-top: 1px !important;
}

.auth0-lock-form small {
  font-size: 17px;
  font-weight: 500;
  font-family: 'AcuminPro';
}

.auth0-lock-input{
  background: #FFFFFF !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 11px !important;
}
.auth0-lock-name{
  font-size: 40px !important;
  line-height: 45px !important;
}

.auth0-lock-form{
  padding: 0px !important;
  padding-bottom: 25px !important;
}

.auth0-lock-tabs-container{
margin-top: 10px !important;
background-color: red !important;
}

.auth0-lock-tabs{
  background: #000 !important;
}
.auth0-lock-tabs > li > span{
  color: #FFF !important;
}

.auth0-lock-tabs > li > a{
  color: #FFF !important;
}
.auth0-lock-tabs-current{
  box-shadow: none !important;
  border-bottom: 2px solid #fff;
}
.auth0-lock-terms{
  background: none !important;
}

@media screen and (max-width: 480px) {
  html.auth0-lock-html{
    width: 100vw !important;
    overflow: hidden !important;
  }
  .auth0-lock, .auth0-lock-opened {
    display: flex !important;
    position: relative !important;
    height: 100vh !important;
    width: auto !important;
    align-items: center !important;
    padding: 10px !important;
  }
  .auth0-lock-content {
      width: 100%;
      vertical-align: middle;
      background-color: #F6ECE7 !important;
  }

  .auth0-lock-widget, .auth0-lock-content-wrapper{
    width: 80vw !important;
    margin: auto !important;
  }

  .auth0-lock-cred-pane-internal-wrapper{
    justify-content: center;
    padding-left: 1% !important;
  }

  .auth0-lock.auth0-lock .auth0-lock-content-wrapper{
    flex-grow: 0.1 !important;
    margin: 20px 0 !important
  }
  .auth0-lock-submit{
    background-color: #000;
  }
}

.forward-star{
   position: absolute;
   right: 10px;
   transform: scaleX(-1) 
}

.backward-star{
  position: absolute;
  right: 10px;
}